const MidSection = (props) => {
  const getBodyClasses = (variant) => {
    let bodyClass = "main ";
    if (variant === "orange") {
      bodyClass += "orange-bg";
    } else if (variant === "grey") {
      bodyClass += "grey-bg";
    } else {
      bodyClass += "green-bg";
    }
    return bodyClass;
  };

  const variant = props.variant;
  let dt = props.weatherReport["dt"] || props.weatherReport[0]?.["dt"];

  try {
    const date1 = new Date(dt * 1000);
    // Formatting the date and time to Dutch
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('nl-NL', dateOptions).format(date1);
    const formattedTime = new Intl.DateTimeFormat('nl-NL', timeOptions).format(date1);
    // Convert first letter of month to lowercase
    const lowerCaseDate = formattedDate.replace(/([A-Z])\w+/g, word => word.toLowerCase());
    dt = ` op ${lowerCaseDate} om ${formattedTime} uur`;
    console.log(`dt`, dt);
  } catch (error) {
    console.log(`dt format error`, error);
    dt = ``
  }

  return (
    <section class={getBodyClasses(variant)}>
      <div class="dark-bg">
        <div class="container">
          {variant === "grey" && (
            <>
              <h4>Oeps!</h4>
              <p class="description">
                Helaas ontvangen we momenteel geen data van Buienradar. Probeer het later nog eens. Mocht dit probleem
                aanhouden, neem dan contact op met Stichting NHK.
              </p>
            </>
          )}

          {variant === "green" && (
            <>
              <h4>Veel stookplezier!</h4>
              <p class="description">
                De weersomstandigheden in uw regio zijn geschikt om te stoken. Stook de juiste brandstof, doe het met
                verstand. Wij wensen u veel sfeervolle momenten bij het vuur!
              </p>
            </>
          )}

          {variant === "orange" && (
            <>
              <h4>Let op!</h4>
              <p class="description">
                Voordat u het vuur aansteekt, even naar buiten kijken. Is het windstil of mistig? De rook kan in uw
                woonomgeving blijven hangen. Niet alleen hinderlijk voor uw buren, mogelijk ook niet prettig voor uzelf.
              </p>
            </>
          )}

          <div class="location-reset">
            <img src="assets/reset icon.png" />
            <p class="text" onClick={props.onReset}>
              Dit advies is voor {props.weatherReport.stationname || props.weatherReport[0]?.stationname}{dt}. Andere locatie? Klik voor reset.
            </p>
          </div>
        </div>
      </div>
      <div class="container">
        <h6>Altijd makkelijk het Stookalert checken?</h6>
        <p>
          Zet de link naar het Stookalert in de favorieten van de
          browser of op het openingsscherm van je telefoon.
        </p>
      </div>
    </section>
  );
};

export default MidSection;
